<template>
  <div style="border: 1px solid #ccc; margin-top: 10px">
    <!-- 工具栏 -->
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig"/>
    <!-- 编辑器 -->
    <Editor style="height: 400px; overflow-y: hidden" :defaultConfig="editorConfig" v-model="html" @onChange="onChange" @onCreated="onCreated"/>
  </div>
</template>
<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'QuillBar',
  components: { Editor, Toolbar },
  props: {
    contenttext: String
  },
  data () {
    return {
      baseurl: this.$http.defaults.baseURL,
      editor: null,
      html: this.contenttext,
      toolbarConfig: {
        // toolbarKeys: [ /* 显示哪些菜单，如何排序、分组 */ ],
        // excludeKeys: [ /* 隐藏哪些菜单 */ ],
      },
      editorConfig: {
        placeholder: '请输入内容...',
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            maxFileSize: 100 * 1024 * 1024, // 100M
            allowedFileTypes: ['image/*'],
            server: this.$http.defaults.baseURL + '/editor-upload'
          }
        }
      }
    }
  },
  methods: {
    onCreated (editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
    },
    onChange (editor) {
      // 内容改变事件
      this.$emit('inputChange', editor.getHtml())
    }
  },
  mounted () {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = this.contenttext
    }, 1500)
  },
  beforeDestroy () {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
